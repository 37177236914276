













































.input{
  margin-bottom: 26px;

  input {
    width: 100%;
    height: auto;
    padding: 15px 24px;
    border: 1px solid #D5D9E1;
    border-radius: 4px;
    font-size: 15px;
    line-height: 18px;
    color: #1D293F;
    transition: all 200ms ease;

    &::placeholder {
      color: #9ea3a7;
    }

    &:focus {
      outline: none;
      border-color: #00ADFF;
      box-shadow: unset;
    }

    &:hover {
      border-color: #00ADFF;
    }

    &.search {
      height: 40px;
      margin-bottom: 0;
      padding: 12px 16px;
      background-color: #F4F7FC;
      border: unset;
      border-radius: 4px;
      color: #9C9EA3;

      &::placeholder {
        color: #B1BACB;
      }
    }
  }

  &__error {
    display: none;
    opacity: 0;
    font-size: 12px;
    line-height: 17px;
    color: #F2323F;
    transition: all 200ms ease;
  }

  &--invalid {
    input {
      margin-bottom: 8px;
      background-color: #FFEAEA;
      border-color: #F2323F;
    }

    .input__error {
      display: block;
      opacity: 1;
    }
  }
}
